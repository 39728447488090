/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { createContext, useEffect } from "react";
import useCategory from "../hook/useCategory";
import useCookies from "../hook/useCookies";
import useInstructor from "../hook/useInstructor";
import usePrograms from "../hook/useProgrames";
import usePurchase from "../hook/usePurchase";
import useSchedule from "../hook/useSchedule";
import useStream from "../hook/useStream";
import useUsers from "../hook/useUsers";
import useTherapist from "../hook/useTherapist";

export const AppContext = createContext();

export const AppContextProvider = (props) => {
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [mainPlayerSrc, setMainPlayerSrc] = useState({});
  const [sidePlayerSrc, setSidePlayerSrc] = useState(null);
  const [scheduleSlots, setScheduleSlots] = useState([]);
  const [localTracks, setLocalTracks] = useState({
    uid: null,
    localVideoTrack: null,
    localAudioTrack: null,
  });

  const [players, setPlayers] = useState([]);

  const {
    therapists,
    actionLoading,
    addTheraphist,
    getTherapist,
    editTherapist,
    deleteTherapist,
    getCurrentLoggedTherapistDetails,
    loggedInstructorDetails,
    enableTherapistSingle,
    disableTherapistSingle,
    therapistDetailsLoading,
  } = useInstructor();

  const {
    categories,
    categoryLoading,
    getCategory,
    addCategory,
    deleteCategory,
    editCategory,
  } = useCategory();

  const {
    getTherapy,
    programs,
    isLoading,
    addTherapy,
    deleteTherapy,
    scheduleProgram,
    getScheduledTheraphies,
    scheduledTheraphies,
    editTherapy,
    getScheduledTherapyByTherapist,
    sortedScheduledTherapy,
    enableTherapySingle,
    disableTherapySingle,
    popularizeTherapy,
  } = usePrograms();

  const {
    weeklySchedule,
    scheduleLoading,
    getWeeklyScheduleList,
    weeklyScheduleList,
    getListOfScheduledSlots,
    scheduledSlot,
    approveScheduledSlot,
    scheduleByDate,
    getDateScheduleData,
    dateScheduleList,
    getPendingDateSchedule,
    pendingDateScheduleList,
    approveSingleDateSchedule,
  } = useSchedule();

  const { editInstructor } = useTherapist();

  const { usersLoading, registerdUsers, getRegisteredUsers } = useUsers();
  const { purchaseData, purchaseLoading, getPurchaseList } = usePurchase();
  const { getAllScheduledStreams, allStreams } = useStream();

  const { getValueFromCookies, checkValueIsExist } = useCookies({
    key: "UserPersistent",
  });

  useEffect(() => {
    if (checkValueIsExist()) {
      getTherapist();
      getTherapy();
      getCategory();
      getRegisteredUsers();
      getPurchaseList();
      getListOfScheduledSlots();
      getPendingDateSchedule();
      checkLoggedUserIsTherapist({
        onSuccess: () => {
          // Below methods are only works with instructor login
          getScheduledTherapyByTherapist();
          getWeeklyScheduleList();
          getScheduledTheraphies();
          getCurrentLoggedTherapistDetails();
          getAllScheduledStreams();
          getDateScheduleData();
        },
      });
    }
  }, []);

  const checkLoggedUserIsTherapist = ({ onSuccess }) => {
    const cookieValue = getValueFromCookies();
    if (!cookieValue) return;
    const { role } = cookieValue;
    if (role != undefined && role === "instructor") {
      onSuccess();
    }
  };

  const stopAllStream = async () => {
    try {
      if (!window.broadcastClient) return;

      if (
        window.broadcastClient &&
        window.broadcastClient.getVideoInputDevice("camera1")
      ) {
        window.broadcastClient.removeVideoInputDevice("camera1");
      }

      if (
        window.broadcastClient &&
        window.broadcastClient.getAudioInputDevice("microphone")
      ) {
        window.broadcastClient.removeAudioInputDevice("microphone");
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      stream.getTracks().forEach(function (track) {
        track.stop();
      });
      window.videoDevices = null;
      window.audioDevices = null;
    } catch (error) {
      console.error("error is", error);
    }
  };

  return (
    <AppContext.Provider
      value={{
        actionLoading,
        getTherapist,
        editTherapist,
        deleteTherapist,
        therapists,
        getCurrentLoggedTherapistDetails,
        loggedInstructorDetails,
        isLoading,
        programs,
        scheduleProgram,
        addTherapy,
        getTherapy,
        deleteTherapy,
        editTherapy,
        popularizeTherapy,
        categories,
        deleteCategory,
        categoryLoading,
        getCategory,
        editCategory,
        addCategory,
        scheduledTheraphies,
        getScheduledTheraphies,
        getScheduledTherapyByTherapist,
        getWeeklyScheduleList,
        sortedScheduledTherapy,
        stopAllStream,
        addTheraphist,
        registerdUsers,
        usersLoading,
        getRegisteredUsers,
        getPurchaseList,
        purchaseData,
        purchaseLoading,
        scheduleLoading,
        weeklySchedule,
        weeklyScheduleList,
        getListOfScheduledSlots,
        scheduledSlot,
        approveScheduledSlot,
        getAllScheduledStreams,
        allStreams,
        enableTherapistSingle,
        disableTherapistSingle,
        enableTherapySingle,
        disableTherapySingle,
        therapistDetailsLoading,
        remoteUsers,
        setRemoteUsers,
        mainPlayerSrc,
        setMainPlayerSrc,
        sidePlayerSrc,
        setSidePlayerSrc,
        localTracks,
        setLocalTracks,
        scheduleSlots,
        setScheduleSlots,
        scheduleByDate,
        editInstructor,
        getDateScheduleData,
        dateScheduleList,
        getPendingDateSchedule,
        pendingDateScheduleList,
        approveSingleDateSchedule,
        players,
        setPlayers,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

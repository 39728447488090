import React, { useEffect, useState } from "react";
import { APIEndpoints } from "../constants/APIEndpoints";
import BaseClient from "../Helper/BaseClient";

const useUsers = () => {
  const [registerdUsers, setRegisteredUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);

  const getRegisteredUsers = async () => {
    try {
      setUsersLoading(true);
      await BaseClient.get(
        APIEndpoints.listAllRegisteredUsers,
        {
          onSuccess: (res) => {
            if (!Array.isArray(res.data)) return;
            setRegisteredUsers(res.data);
          },
        },
        {
          onFailed: (err) => {
            return console.log("Error", err);
          },
        }
      );
    } finally {
      setUsersLoading(false);
    }
  };

  return { getRegisteredUsers, usersLoading, registerdUsers };
};

export default useUsers;

import axios from "axios";
import Cookies from "universal-cookie";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const cookies = new Cookies();

//Create a axios api instance
const api = axios.create({
  baseURL: BASE_URL,
});

//Interceptor for handle the response
api.interceptors.response.use(
  function (response) {
    let errorResponse = { message: "Someting Went Wrong", error: true };
    if (
      response.data != null &&
      response.data.error != null &&
      response.data.error === false
    ) {
      return response.data;
    }
    return Promise.reject(errorResponse);
  },
  function (res) {
    if (
      res.response.status === 401 &&
      res.response.statusText == "Unauthorized" &&
      !window.location.href.endsWith("/login")
    ) {
      cookies.remove("UserPersistent", { path: "/" });
      window.location.href = "/login";
      return Promise.reject(res.response.data);
    }
    let errorResponse = { message: "Someting Went Wrong", error: true };
    if (res != null && res.response != null && res.response.data != null) {
      errorResponse = res.response.data;
    }
    return Promise.reject(errorResponse);
  }
);

class BaseClient {
  //Get Method
  static async get(endpoint, { headers, onSuccess, onFailed }) {
    headers = headers || { "Content-Type": "application/json" };
    this.addAuthHeader(headers);
    const config = { headers };
    await api
      .get(endpoint, config)
      .then((data) => onSuccess && onSuccess(data))
      .catch((error) => onFailed && onFailed(error));
  }

  //Post Method
  static async post(endpoint, payload, { headers, onSuccess, onFailed }) {
    headers = headers || {
      "Content-Type": "application/json",
    };
    this.addAuthHeader(headers);
    const config = { headers };

    await api
      .post(endpoint, payload, config)
      .then((data) => onSuccess && onSuccess(data))
      .catch((error) => onFailed && onFailed(error));
  }

   //Post Method
   static async patch(endpoint, payload, { headers, onSuccess, onFailed }) {
    headers = headers || {
      "Content-Type": "application/json",
    };
    this.addAuthHeader(headers);
    const config = { headers };

    await api
      .patch(endpoint, payload, config)
      .then((data) => onSuccess && onSuccess(data))
      .catch((error) => onFailed && onFailed(error));
  }

  //Put Method
  static async put(
    endpoint,
    payload,
    { headers, params, onSuccess, onFailed }
  ) {
    headers = headers || { "Content-Type": "application/json" };

    this.addAuthHeader(headers);
    const config = { headers, params };

    await api
      .put(endpoint, payload, config)
      .then((data) => onSuccess && onSuccess(data))
      .catch((error) => onFailed && onFailed(error));
  }

  //Delete Method
  static async delete(endpoint, { headers, onSuccess, onFailed }) {
    headers = headers || { "Content-Type": "application/json" };

    this.addAuthHeader(headers);
    const config = { headers };

    await api
      .delete(endpoint, config)
      .then((data) => onSuccess && onSuccess(data))
      .catch((error) => onFailed && onFailed(error));
  }

  static addAuthHeader(headers) {
    const cookiesValue = cookies.get("UserPersistent");
    const token =
      cookiesValue?.accessToken && `Bearer ${cookiesValue.accessToken}`;

    if (token) {
      return (headers.authorization = token);
    }
    return headers;
  }
}

export default BaseClient;

import React, { useEffect, forwardRef, useContext } from "react";
import { AppContext } from "../../context/AppContext";

const MediaPlayer = forwardRef((props, ref) => {
  const { registerdUsers } = useContext(AppContext);
  useEffect(() => {
    if (!ref || !ref.current || !props.videoTrack) return;
    props.videoTrack.play(ref.current);
    props.onPlayerReady && props.onPlayerReady(ref.current);
    return () => {
      props.videoTrack.stop();
    };
  }, [ref, props.videoTrack]);

  useEffect(() => {
    if (!props.audioTrack) return;
    props.audioTrack.play();
    return () => {
      props.audioTrack.stop();
    };
  }, [props.audioTrack]);

  const getRemoteUserById = (id) => {
    const uid = id.substring(4);

    if (!registerdUsers || registerdUsers.length == 0) return;
    const user = registerdUsers.find((user) => uid == user._id);
    if (!user) return;

    return user.name;
  };

  return (
    <div
      ref={ref}
      id={props.id}
      className={props.classNames ? props.className : "video-player"}
      style={
        props.style
          ? props.style
          : {
              width: "320px",
              height: "240px",
              position: "relative",
              top: "0",
              left: "0",
            }
      }
    >
      <div
        className={
          props.mainPlayer ? "name-screen main_player" : "name-screen side"
        }
      >
        {props.remoteUserId && props.remoteUserId != null ? (
          <span>{getRemoteUserById(props.remoteUserId)}</span>
        ) : (
          "Not Available"
        )}
      </div>
    </div>
  );
});

export default MediaPlayer;

import React, { useState, useEffect } from "react";
import { useContext } from "react";
import * as Bs from "react-icons/bs";
import { AppContext } from "../../context/AppContext";

export default function SchedulePendingList(props) {
  const { programs } = useContext(AppContext);

  return (
    <>
      {props.scheduleList &&
        props.scheduleList &&
        props.scheduleList.length > 0 && (
          <li class="card-data">
            <div>
              <h3 class="card-title week_title">{props.weekName}</h3>
              {props.scheduleList.map((schedule) => {
                const therapyData = programs.find(
                  (program) => program._id == schedule.therapyId
                );
                const therapist = props.slotSchedule.therapistDetails;

                return (
                  <div class="card-content sprd-data">
                    <p>{therapyData?.therapyName ?? "N/A"}</p>
                    <p>{therapist?.name ?? "N/A"}</p>
                    <p style={{ fontSize: "12px" }}>
                      {schedule?.startTime ?? "N/A"} to{" "}
                      {schedule?.endTime ?? "N/A"}
                    </p>
                    <p>
                      {therapyData?.status == "enabled" ? (
                        <button
                          className="approve"
                          onClick={() =>
                            props.onClickApprove(schedule, therapist)
                          }
                        >
                          {" "}
                          <Bs.BsCheckAll />{" "}
                        </button>
                      ) : (
                        <p>Service Disabled</p>
                      )}
                    </p>
                  </div>
                );
              })}
            </div>
          </li>
        )}
    </>
  );
}

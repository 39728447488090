import React, { useEffect } from "react";
import Header from "../../Components/Header/Header";
import "./Lives.css";
import Player from "../../Components/Player/Player";
import Broadcast from "../Broadcast/Broadcast";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
function Lives() {

  return (
    <>
      <header>
        <Header />
      </header>
      <main>
        <section class="main">
        <Container>
          <Row>
          <h2 className="all">All Lives</h2>
          </Row>
        </Container>
          {/* <Container>
      <Row>
        <Col>
        <div className="card live_cards">
          <div className="live_stat">
            <span> <button className="button_live">Live</button></span>
            <label  className="bolder" for="title">Title : </label><span>test</span>
           </div>
          <div className="live_cont d-flex">
            <label  className="bolder" for="streamer">Streamer : </label>
            <p>#gamer</p>
          </div>
          <div className="live_cont d-flex">
            <label  className="bolder" for="des">Description: </label>
            <p>Not available</p>
          </div>
          <div className="live_cont d-flex">
            <label className="bolder" for="title">Title : </label>
            <p>test</p>
          </div>
          <button className="view_btn">View Live</button>
        </div>
        </Col>
        <Col>
        <div className="card live_cards">
          <div className="live_stat">
            <span> <button className="button_live">Live</button></span>
            <label  className="bolder" for="title">Title : </label><span>test</span>
           </div>
          <div className="live_cont d-flex">
            <label  className="bolder" for="streamer">Streamer : </label>
            <p>#gamer</p>
          </div>
          <div className="live_cont d-flex">
            <label  className="bolder" for="des">Description: </label>
            <p>Not available</p>
          </div>
          <div className="live_cont d-flex">
            <label className="bolder" for="title">Title : </label>
            <p>test</p>
          </div>
          <button className="view_btn">View Live</button>
        </div>
        </Col>
        <Col>
        <div className="card live_cards">
          <div className="live_stat">
            <span> <button className="button_live">Live</button></span>
            <label  className="bolder" for="title">Title : </label><span>test</span>
           </div>
          <div className="live_cont d-flex">
            <label  className="bolder" for="streamer">Streamer : </label>
            <p>#gamer</p>
          </div>
          <div className="live_cont d-flex">
            <label  className="bolder" for="des">Description: </label>
            <p>Not available</p>
          </div>
          <div className="live_cont d-flex">
            <label className="bolder" for="title">Title : </label>
            <p>test</p>
          </div>
          <button className="view_btn">View Live</button>
        </div>
        </Col>
        <Col>
        <div className="card live_cards">
          <div className="live_stat">
            <span> <button className="button_live">Live</button></span>
            <label  className="bolder" for="title">Title : </label><span>test</span>
           </div>
          <div className="live_cont d-flex">
            <label  className="bolder" for="streamer">Streamer : </label>
            <p>#gamer</p>
          </div>
          <div className="live_cont d-flex">
            <label  className="bolder" for="des">Description: </label>
            <p>Not available</p>
          </div>
          <div className="live_cont d-flex">
            <label className="bolder" for="title">Title : </label>
            <p>test</p>
          </div>
          <button className="view_btn">View Live</button>
        </div>
        </Col>
      </Row>
    </Container> */}
          {/* <Broadcast /> */}
        </section>
      </main>
    </>
  );
}

export default Lives;

import { useState } from "react";
import BaseClient from "../Helper/BaseClient";
import { APIEndpoints } from "../constants/APIEndpoints";

const usePurchase = () => {
  const [purchaseData, setPurchaseData] = useState([]);
  const [purchaseLoading, setPurchaseLoading] = useState(false);

  // Get Purchase Data
  const getPurchaseList = async () => {
    try {
      await BaseClient.get(APIEndpoints.listAllPruchases, {
        onSuccess: (res) => {
          if (!Array.isArray(res.result) || res.result.length == 0) return;
          
          setPurchaseData(res.result);
        },
        onFailed: (err) => console.log(err),
      });
    } finally {
      setPurchaseLoading(false);
    }
  };
  return { getPurchaseList, purchaseData, purchaseLoading };
};

export default usePurchase;

import { useState } from "react";
import BaseClient from "../Helper/BaseClient";
import { APIEndpoints } from "../constants/APIEndpoints";
import useCookies from "./useCookies";

const useAuth = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { deleteValueFromCookies, checkValueIsExist, getValueFromCookies } =
    useCookies({
      key: "UserPersistent",
    });

  const loginUser = async (data, { onSuccess, onFailed }) => {
    try {
      setIsLoading(true);
      await BaseClient.post(APIEndpoints.login, data, {
        onSuccess,
        onFailed,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const logoutUser = () => checkValueIsExist() && deleteValueFromCookies();

  const confirmTherapistPassword = async (data, { onSuccess, onFailed }) => {
    try {
      setIsLoading(true);
      await BaseClient.post(APIEndpoints.confirmTherapist, data, {
        onSuccess,
        onFailed,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, loginUser, logoutUser, confirmTherapistPassword };
};

export default useAuth;

import React from "react";
import { Circles } from "react-loader-spinner";
import Lottie from "lottie-react";
import { Spinner } from "react-bootstrap";
import SessionLoader from "../../assets/lottie/gMT04OvKT6.json";

export default function CircleLoader(props) {
  return (
    <div className="loader">
      {props.type == "circleLoader" ? (
        <Circles
          height={props.height}
          width={props.width}
          color="#047484"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      ) : props.type == "lottieLoader" ? (
        <>
          <div class="loader" style={{ display: "block" }}>
            <div class="" style={{ width: "200px", height: "400px" }}>
              <Lottie
                animationData={SessionLoader}
                style={{
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  height: props.height,
                  width: props.width,
                }}
              ></Lottie>
            </div>
            <h1>Joining</h1>
          </div>
        </>
      ) : (
        <Spinner animation="border" variant="primary" />
      )}
      <br />
      <p
        style={{
          paddingTop: "10px",
        }}
      >
        {props.message}
      </p>
    </div>
  );
}

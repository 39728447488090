import React from 'react'
import Header from '../../Components/Header/Header'
function Subcategories() {
  return (
   <>
   <header>
        <Header />
      </header>
     <main>
     <button className="add_btn mt-3">
            + Add
          </button>
        <section className="main">

        <h2 className='text-center mt-3'>Sub-Categories</h2>
        
        </section>
      </main>
   </>
  )
}

export default Subcategories
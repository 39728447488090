export default class Utils {
  static checkObjectAllValuesDefined(obj) {
    for (let prop in obj) {
      if (typeof obj[prop] === "undefined") {
        return false;
      }
    }
    return true;
  }

  static createFormDataFromFormValues(values) {
    const form = new FormData();

    Object.keys(values).forEach(function (key, index) {
      form.append(key, values[key]);
    });
    return form;
  }

  static reverseDateString(dateString) {
    var parts = dateString.split("-");
    if (parts.length === 3) {
      var year = parts[0];
      var month = parts[1];
      var day = parts[2];
      return day + "-" + month + "-" + year;
    } else {
      throw new Error(
        "Invalid date string format. Expected format: yyyy-mm-dd"
      );
    }
  }

  static formatDate(value) {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Create a normal date string
    const normalDate = `${month}-${day}-${year}`;

    return normalDate;
  }

  static createBlobUrl(base64String) {
    const binaryData = atob(base64String.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: "image/png" });
    return URL.createObjectURL(blob);
  }

  static createBlobFile(base64String) {
    const binaryData = atob(base64String.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: "image/png" });
    return new File([blob], "image.png");
  }
}

import React, { useEffect, useContext, useLayoutEffect, useRef } from "react";
import * as Bs from "react-icons/bs";
import * as Ai from "react-icons/ai";
import * as Si from "react-icons/si";
import * as Ri from "react-icons/ri";
import * as Md from "react-icons/md";
import "./LiveSession.css";
import { Row, Col, Container } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MediaPlayer from "../../Pages/streamManager/Mediaplayer";
import useChat from "../../hook/useChat";
import { height } from "@mui/system";

function ChatSession(props) {
  const newMsg = useRef(null);
  const pinnedref = useRef(false);
  const {
    mainPlayerSrc,
    localTracks,
    loggedInstructorDetails,
    remoteUsers,
    players,
    setPlayers,
    allStreams,
    programs,
    registerdUsers,
  } = useContext(AppContext);

  const { message, chatMessages, clearChat, sendMessageToRoom, setMessage } =
    useChat({
      chatRoomId: props.chatRoomId,
      userDetails: loggedInstructorDetails,
      userType: "Therapist",
    });

  const onChangeMessageField = (e) => {
    setMessage(e.target.value);
  };

  const onPressKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      sendMessageToRoom();
    }
  };
  // useEffect(() => [
  //   console.log("remoteUsers",remoteUsers)
  // ],[])
  useEffect(() => {
    const container = newMsg.current;
    if (!pinnedref.current) {
      container.scrollTop = container.scrollHeight;
    }
  });

  useEffect(() => {
    console.log("registerdUsers", registerdUsers);
  }, [registerdUsers]);

  useLayoutEffect(() => {
    const container = newMsg.current;
    const pinned =
      container.scrollHeight - container.scrollTop === container.clientHeight;
    pinnedref.current = pinned;
    // console.log((pinnedref.current = pinned));
  });

  return (
    <>
      <div className="chat_main">
        <div className="chat_head">
          <div className="tab-div mx-auto">
            <Tabs
              defaultActiveKey="All"
              className="mx-auto m-3"
              justify
              style={{ width: "100%" }}
            >
              <Tab
                eventKey="All"
                title={
                  <span style={{ fontSize: "13px" }}>
                    <i style={{ fontSize: "17px", marginRight: "2px" }}>
                      <Ri.RiUserSmileFill />
                    </i>{" "}
                    Participants
                  </span>
                }
                id="scrollable"
              >
                <div className="scroll-area">
                  {remoteUsers.length != 0 &&
                    localTracks != null &&
                    Object.keys(localTracks).length != 0 && (
                      <div className="card part_card mx-auto">
                        <MediaPlayer
                          id={"sidePlayer"}
                          videoTrack={localTracks.localVideoTrack}
                          audioTrack={localTracks.localAudioTrack}
                          style={{ aspectRatio: "16/9" }}
                          ref={React.createRef()}
                        />
                      </div>
                    )}
                  {remoteUsers.map((user, index) => {
                    const player = players.find((p) => p.uid === user.uid);
                    if (!player) {
                      const newPlayer = {
                        uid: user.uid,
                        ref: React.createRef(),
                      };
                      setPlayers([...players, newPlayer]);
                    }

                    const pinnedUserId =
                      window.sessionStorage.getItem("pinnedUserId");
                    if (pinnedUserId == user.uid) {
                      return null;
                    }

                    // const matches = props.checkMainPlayerUidMatches(user.uid);
                    // if (matches == true) {
                    //   return null;
                    // }
                    return (
                      <div className="card part_card mx-auto">
                        <div className="cont">
                          <div key={user.uid} className="mini-player">
                            <button
                              className="pin_btn"
                              onClick={() => {
                                const currentPlayer = players.find(
                                  (p) => p.uid === user.uid
                                );
                                return (
                                  props.onClickPin &&
                                  props.onClickPin({
                                    index,
                                    videoTrack: user.videoTrack,
                                    audioTrack: user.audioTrack,
                                    uid: user.uid,
                                    player:
                                      currentPlayer &&
                                      currentPlayer.ref.current,
                                  })
                                );
                              }}
                            >
                              <Bs.BsFillPinAngleFill />
                            </button>
                            {player && (
                              <MediaPlayer
                                id={`player-${user.uid}`}
                                videoTrack={user.videoTrack}
                                audioTrack={user.audioTrack}
                                style={{ aspectRatio: "16/9" }}
                                onPlayerReady={(player) => {
                                  return (
                                    props.onPlayerReady &&
                                    props.onPlayerReady(player)
                                  );
                                }}
                                ref={player.ref}
                                remoteUserId={user.uid}
                                mainPlayer={false}
                              />
                            )}
                            <button className="btn-cont">
                              {!user.hasAudio ? (
                                <Bs.BsMicFill />
                              ) : (
                                <Bs.BsMicMuteFill />
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Tab>

              <Tab
                eventKey="Messages"
                title={
                  <span style={{ fontSize: "13px" }}>
                    <i style={{ fontSize: "17px", marginRight: "2px" }}>
                      <Si.SiGooglemessages />
                    </i>{" "}
                    Messages
                  </span>
                }
                className="tab"
              >
                <button class="clear_chat" onClick={() => clearChat()}>
                  <Md.MdClearAll />
                </button>
                <div className="chat_area_left mt-3" id="chat" ref={newMsg}>
                  <div class="chat_info">
                    <h5>Please let us know if you face any issues.</h5>
                    <h6>
                      <Ai.AiOutlineMail /> mail@nesthealthcare.com
                    </h6>
                  </div>

                  {/* chat Content */}

                  {loggedInstructorDetails &&
                    Array.isArray(chatMessages) &&
                    chatMessages.length != 0 &&
                    chatMessages.map((message) => {
                      const currentUser =
                        message.userId == loggedInstructorDetails._id;

                      return !currentUser ? (
                        <div class="chat d-flex">
                          <div class="avt">
                            <img
                              src={require("../../assets/avatar2.png")}
                              alt=""
                            />
                          </div>
                          <div class="txt">
                            <div className="txt_cont">{message.message}</div>
                          </div>
                        </div>
                      ) : (
                        <div class="chat-right d-flex">
                          <div class="txt">
                            <div className="txt_cont">{message.message}</div>
                          </div>
                          <div class="avt" id="right">
                            <img
                              src={require("../../assets/avatar2.png")}
                              alt=""
                            />
                          </div>
                        </div>
                      );
                    })}
                  {/* chat content end */}
                </div>
                {/* Chat Area end */}
                <div className="sent-box">
                  <input
                    className="p-3"
                    placeholder="write a message...."
                    onChange={onChangeMessageField}
                    onKeyDown={onPressKeyDown}
                    value={message}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatSession;

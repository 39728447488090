import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import useAuth from "../../hook/useAuth";
import { BeatLoader } from "react-spinners";
import useCookies from "../../hook/useCookies";
import jwt_decode from "jwt-decode";
// import { AES, enc, utf8 } from "crypto-js";

function TherapistLogin() {
  const params = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewpassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [error, setError] = useState(false);
  const [decodedToken, setDecodedToken] = useState(null);

  //Hook for Auth API
  const { confirmTherapistPassword, isLoading } = useAuth();

  //Hook for Cookies
  const { deleteValueFromCookies } = useCookies({ key: "UserPersistent" });

  //Clear existing cookie
  useEffect(() => {
    // decrypt(params.token);
    deleteValueFromCookies();
    // getDataFromToken();
  }, []);

  const getDataFromToken = () => {
    if (!params.token) return;
    const decodedData = jwt_decode(params.token);
    if (!decodedData) return;
    setDecodedToken(decodedData);
  };

  const therpistConfirm = (e) => {
    e.preventDefault();

    //Field Validation
    if (!newPassword || !confirmPassword) {
      setError(true);
      console.log("data", error);
      return;
    }
    if (newPassword != confirmPassword) {
      toast.error("Password doesn't match! Please try again");
      return;
    }

    confirmTherapist(newPassword);
  };

  const confirmTherapist = async (password) => {
    const payload = {
      token: params.token,
      password: password,
    };

    await confirmTherapistPassword(payload, {
      onSuccess: () => {
        toast.success("Your Account has been active successfully!");
        setTimeout(() => {
          navigate("/login");
        }, 800);
      },
      onFailed: (err) => {
        // customToast();
        toast.error(err.message);
      },
    });
  };
  // const customToast = toast.custom((t) => {
  //   return (
  //     <>
  //       <div
  //         className={`bg-white px-6 py-4 shadow-md rounded-full ${
  //           t.visible ? "animate-enter" : "animate-leave"
  //         }`}
  //       >
  //         <p>Hi there</p>
  //       </div>
  //     </>
  //   );
  // });

  // function decrypt(data) {
  //   const decipher = crypto.createDecipher(algorithm, '62uWKjJx0e');
  //   let dec = decipher.update(data, "hex", "utf8");
  //   dec += decipher.final("utf8");
  //   debugger;
  //   return dec;
  // }

  return (
    <>
      <Toaster position="top-center" />
      <div className="login_container">
        <h2 className="login_title">
          Welcome to <b> Estman Solicitors and Notary</b>.<br /> Please Login
          and Start!
        </h2>
        <div className="loginForm">
          <div className="logo" id="">
            <img src={require("../../assets/Eastman/Eastman-logo1.png")} />
          </div>
          <h2 style={{ textAlign: "center" }}>Login</h2>

          {/* <h5 style={{ textAlign: "center" }}>
            Your username is <b>{decodedToken?.email ?? "Unkown"}</b>
          </h5> */}
          <form onSubmit={(e) => therpistConfirm(e)}>
            <div class="form-group mt-4">
              <label for="new Passowrd">New Password</label>
              <input
                type="text"
                name=""
                id=""
                placeholder="Enter Your password"
                className={
                  error && newPassword?.length === 0
                    ? "form_field validated"
                    : "form_field "
                }
                value={newPassword}
                onChange={(e) => setNewpassword(e.target.value)}
              />
              {error && !newPassword ? (
                <>
                  <span className="errors_">New Password Cannot be blank</span>
                </>
              ) : (
                ""
              )}
            </div>
            <div class="form-group mt-2">
              <label for="new Passowrd">Confirm Password</label>
              <input
                type="password"
                name=""
                id=""
                className={
                  error && confirmPassword?.length === 0
                    ? "form_field validated"
                    : "form_field "
                }
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm your password"
              />
              {error && !confirmPassword ? (
                <>
                  <span className="errors_">
                    Please confirm the new Password!
                  </span>
                </>
              ) : (
                ""
              )}
            </div>
            <br />
            <button type="submit" className="login_btn">
              {isLoading ? <BeatLoader color="#fff" /> : "Login"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default TherapistLogin;

import BaseClient from "../Helper/BaseClient";
import { useState } from "react";
import { APIEndpoints } from "../constants/APIEndpoints";

const useCategory = () => {
  const [categories, setCategories] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState({
    action: "",
    loading: false,
  });

  // Get Category
  const getCategory = async () => {
    try {
      setCategoryLoading({ action: "Get", loading: true });
      await BaseClient.get(
        APIEndpoints.getCategory,
        {
          onSuccess: (res) => {
            if (!Array.isArray(res.data)) return;
            setCategories(res.data);
          },
        },
        {
          onFailed: (err) => {
            return console.log(err);
          },
        }
      );
    } finally {
      setCategoryLoading({ action: "", loading: false });
    }
  };

  // Post Method
  const addCategory = async (payload, { onSuccess, onFailed }) => {
    try {
      setCategoryLoading({ action: "Add", loading: true });
      await BaseClient.post(APIEndpoints.addCategory, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setCategoryLoading({ action: "", loading: false });
    }
  };  

  // Edit Category
  const editCategory = async (data, id, { onSuccess, onFailed }) => {
    try {
      const payload = data;
      setCategoryLoading({ action: "Edit", loading: true });

      await BaseClient.put(APIEndpoints.editCategory + `/${id}`, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      getCategory();
      setCategoryLoading({ action: "", loading: false });
    }
  };

  // Delete Category
  const deleteCategory = async (id, { onSuccess, onFailed }) => {
    try {
      const payload = { categoryId: id };
      setCategoryLoading({ action: "Delete", loading: true });
      await BaseClient.post(APIEndpoints.deleteCategory, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      getCategory();
      setCategoryLoading({ action: "", loading: false });
    }
  };

  return {
    getCategory,
    categories,
    addCategory,
    categoryLoading,
    deleteCategory,
    editCategory,
  };
};
export default useCategory;

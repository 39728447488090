import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import * as Ai from "react-icons/ai";
import "react-datepicker/dist/react-datepicker.css";
import ReactSpinner from "react-bootstrap-spinner";
import "./Home.css";
import { AppContext } from "../../context/AppContext";
import { Row } from "react-bootstrap";
import img1 from "../../assets/Eastman/Eastman-logo1.png";
import jwt_decode from "jwt-decode";
import useCookies from "../../hook/useCookies";
import useInstructor from "../../hook/useInstructor";
import { color } from "@mui/system";
function Home() {
  const navigate = useNavigate();
  const [isRole, setIsRole] = useState(null);
  const { therapists, registerdUsers, scheduledSlot } = useContext(AppContext);
  const { loggedInstructorDetails } = useContext(AppContext);
  const { getValueFromCookies } = useCookies({ key: "UserPersistent" });
  useEffect(() => {
    const { role } = getValueFromCookies();
    setIsRole(role);
  }, []);
  console.log(therapists, "therapist");
  const navigateToProfile = (id) => {
    navigate(`/profile-instructor/${id}`);
  };

  return (
    <>
      <header>
        <Header />
      </header>
      {/* header end */}
      <main>
        {isRole === "superadmin" && (
          <section class="main ml-3">
            <h1>Welcome Admin</h1>
            <div class="container ml-3">
              <Row>
                <div className="col-lg-4 col-md-6 col-sm-6 ">
                  <div className="card dash_card instructor four">
                    <h3>Lawyers</h3>
                    <h2>{therapists.length}</h2>
                    <div className="dash_icon ">
                      <img src={require("../../assets/teacher.png")} alt="" />
                    </div>
                    <button
                      onClick={() => navigate("/therapist")}
                      className="dash_link"
                    >
                      <Ai.AiOutlineArrowRight />
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card dash_card instructor three">
                    <h3>Clients</h3>
                    <h2>{registerdUsers.length}</h2>
                    <div className="dash_icon ">
                      <img src={require("../../assets/team.png")} alt="" />
                    </div>
                    <a href="/" className="dash_link">
                      <Ai.AiOutlineArrowRight />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="card dash_card instructor two">
                    <h3>Sessions</h3>
                    <h2>{scheduledSlot.length}*</h2>
                    <div className="dash_icon ">
                      <img src={require("../../assets/webinar.png")} alt="" />
                    </div>
                    <a href="/lives" className="dash_link">
                      <Ai.AiOutlineArrowRight />
                    </a>
                  </div>
                </div>
              </Row>
              <br />
            </div>
            <div class="card shadow border-0 mb-7 p-2">
              <div class="card-header">
                <h6 class="mb-0">List of Recently Added Lawyers</h6>
              </div>
              <div class="table-responsive">
                <table class="table table-hover table-nowrap">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">Profile</th>
                      <th scope="col">Name</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {therapists != null &&
                      Array.isArray(therapists) &&
                      therapists.map((data, index) => {
                        return (
                          <tr>
                            <td>
                              <img
                                alt="Profile Imgae"
                                className="avatar avatar-sm rounded-circle me-2"
                                src={
                                  data != null
                                    ? process.env.REACT_APP_IMAGE_URL +
                                      data.photo
                                    : img1
                                }
                              />
                            </td>
                            <td>{data.name ?? "N/A"}</td>

                            <td>{data.phonenumber ?? "N/A"}</td>
                            <td>{data.desigination ?? "N/A"}</td>
                            <td>{data.email ?? "N/A"}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div class="card-footer border-0 py-5">
                <span class="text-muted text-sm">
                  Showing details of Lawyer
                </span>
              </div>
            </div>
          </section>
        )}
        {isRole === "instructor" && (
          <section class="main ml-3">
            <div class="container mt-4">
              <div class="row">
                <div class="col-sm-6 col-md-4 col-lg-4">
                  <div class="card inst_card therapist p-3">
                    <h2>Sessions</h2>
                    <p>
                      Assigned Sessions: <span>5</span>
                    </p>
                    <p>
                      Session Details: <span>Family Law</span>
                      <i>
                        <Ai.AiFillInfoCircle />
                        <div class="tooltip-therapist">
                          <p>list of sessions</p>
                        </div>
                      </i>
                    </p>
                    <p>
                      Users: <span>7</span>
                    </p>
                    <div className="dash_icon">
                      <img src={require("../../assets/teacher.png")} alt="" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6">
                  <div class="card inst_card therapist p-4">
                    <h2>Upcoming session</h2>
                    <p>
                      Title: <span>Family Law</span>
                    </p>
                    <p>
                      User: <span>Joshua Mathew</span>
                    </p>
                    <p>
                      Date: <span>22/01/2023</span>
                    </p>
                    <p>
                      Duration <span>10:00AM - 11:00AM</span>
                    </p>
                    <div className="dash_icon">
                      <img src={require("../../assets/upcoming.png")} alt="" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6">
                  <div class="card inst_card therapist p-4">
                    <h2>Profile</h2>
                    {loggedInstructorDetails == null ? (
                      <div className="spinner-border" role="status"></div>
                    ) : (
                      <>
                        <p>
                          Full Name:{" "}
                          <span>{loggedInstructorDetails?.name ?? "N/A"}</span>
                        </p>
                        <p>
                          Experience:{" "}
                          <span>
                            {loggedInstructorDetails?.experience ?? "N/A"}
                          </span>
                        </p>
                        <p>
                          Service:{" "}
                          <span>
                            {loggedInstructorDetails?.desigination ?? "N/A"}
                          </span>
                        </p>
                        <p>
                          Phone:{" "}
                          <span>
                            {loggedInstructorDetails?.phonenumber ?? "N/A"}
                          </span>
                        </p>
                        {/* <button
                          type="button"
                          className="button-thera"
                          onClick={() =>
                            navigateToProfile(loggedInstructorDetails._id)
                          }
                        >
                        <i className=""><Ai.AiOutlineEdit/></i>
                          Edit
                        </button> */}
                        <button
                          class="mdc-button mdc-button--raised mdc-button--leading button-thera"
                          type="button"
                          className="button-thera"
                          onClick={() =>
                            navigateToProfile(loggedInstructorDetails._id)
                          }
                        >
                          <i
                            class="material-icons mdc-button__icon"
                            aria-hidden="true"
                          >
                            <Ai.AiOutlineEdit />
                          </i>
                          <span class="mdc-button__label">Edit</span>
                        </button>
                        <div class="profile_pic_therapist">
                          <img
                            src={
                              loggedInstructorDetails.photo == null
                                ? require("../../assets/placeholder.webp")
                                : process.env.REACT_APP_IMAGE_URL +
                                  loggedInstructorDetails.photo
                            }
                            alt=""
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </main>
      {/* main end */}
      <footer></footer>
    </>
  );
}

export default Home;

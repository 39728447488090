import React, { useContext } from "react";
import Header from "../../Components/Header/Header";
import "./StreamManager.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AllStreams from "./AllStreams";
import StreamsList from "./StreamsList";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

function StreamManager() {
  const navigate = useNavigate();

  const { allStreams } = useContext(AppContext);

  const whenStreamStart = (streamData) => {
    // console.log(streamData, "streamData")
    navigate("/therapy-session/" + streamData._id);
  };
  console.log("all", allStreams);
  return (
    <>
      <Header />
      <main>
        <section class="main">
          <h1>Session Manager</h1>
          <Tabs
            defaultActiveKey="All"
            id="justify-tab-example"
            className="mb-3 text-wrap"
            justify
          >
            <Tab eventKey="All" title="All Sessions">
              <AllStreams
                onStreamStart={(streamData) => whenStreamStart(streamData)}
              />
            </Tab>
            <Tab eventKey="Upcoming" title="Upcoming">
              {allStreams.schedules && (
                <StreamsList
                  streams={allStreams.schedules.filter(
                    (stream) =>
                      stream.streamStatus != "STARTED" &&
                      stream.streamStatus != "STOPPED"
                  )}
                  therapist={allStreams.therapistDetails}
                  onStreamStart={(streamData) => whenStreamStart(streamData)}
                  finished={false}
                />
              )}
            </Tab>
            <Tab eventKey="longer-tab" title="Finished">
              {allStreams.schedules && (
                <StreamsList
                  streams={allStreams.schedules.filter(
                    (stream) =>
                      stream.streamStatus != "UPCOMING" &&
                      stream.streamStatus != "STARTED"
                  )}
                  therapist={allStreams.therapistDetails}
                  onStreamStart={(streamData) => whenStreamStart(streamData)}
                  finished={true}
                />
              )}
            </Tab>
          </Tabs>
        </section>
      </main>
    </>
  );
}

export default StreamManager;

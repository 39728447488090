import Cookies from "universal-cookie";

const useCookies = ({ key }) => {
  const cookies = new Cookies(); 

  const saveValueToCookies = (value) => {
    const cookieValue = getValueFromCookies();

    if (cookieValue) {
      deleteValueFromCookies();
    }
    cookies.set(key, value, { path: "/" });
  };

  const getValueFromCookies = () => {
    return cookies.get(key);
  };

  const deleteValueFromCookies = () => {
    cookies.remove(key, { path: "/" });
  };

  const checkValueIsExist = () => {
    return cookies.get(key) != null;
  };

  return {
    saveValueToCookies,
    getValueFromCookies,
    deleteValueFromCookies,
    checkValueIsExist,
  };
};

export default useCookies;

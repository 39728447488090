import React, { useContext, useState, useEffect } from "react";
import * as Ai from "react-icons/ai";
import { Form, Row, Col } from "react-bootstrap";
import { DatePicker, Space } from "antd";
import { AppContext } from "../../context/AppContext";
import toast, { Toaster } from "react-hot-toast";
import "./ScheduleManagement.css";

import "antd/dist/reset.css";
import "./ScheduleManagement.css";

function ScheduleModal(props) {
  const { therapists, programs, scheduleProgram, getScheduledTheraphies } =
    useContext(AppContext);

  const [dateValue, setDateValue] = useState();
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [price, setPrice] = useState(null);
  const [selectedTheraphy, setSelectedTheraphy] = useState(null);
  const [selectedTheraphist, setSelectedTheraphist] = useState(null);

  if (!props.open) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !selectedTheraphy ||
      !selectedTheraphist ||
      !dateValue ||
      !startTime ||
      !endTime ||
      !price
    ) {
      return;
    }

    await scheduleProgram({
      payload: {
        instructorId: selectedTheraphist,
        therapyId: selectedTheraphy,
        startTime: startTime,
        endTime: endTime,
        date: dateValue,
        price,
      },
      onSuccess: () => {
        getScheduledTheraphies();
        toast.success("Theraphy Scheduled Successfully");
        setTimeout(() => {
          props.onClose();
        }, 2000);
      },
      onFailed: () => {
        toast.failed("Theraphy Failed to Schedule !");
      },
    });
  };

  return (
    <>
      <Toaster position="top-center" />
      <div className="modal_wrappers">
        <div className="modal_containers" id="schedule_modal">
          <div className="close" onClick={props.onClose}>
            <Ai.AiOutlineClose />
          </div>
          <h2 className="text-center mt-2">Add Schedule</h2>
          <form
            className="form-control add_form modal_content"
            onSubmit={(e) => handleSubmit(e)}
          >
            <Row>
              <Col>
                <div class="form-group">
                  <label for="">Therapy</label>
                  <select
                    name=""
                    id=""
                    class="form-select"
                    value={selectedTheraphy}
                    onChange={(e) => {
                      setSelectedTheraphy(e.target.value);
                    }}
                  >
                    <option value="0" selected disabled>
                      Select Therapy
                    </option>
                    {Array.isArray(programs) &&
                      programs.map((therapy) => {
                        const data = JSON.parse(therapy.category);
                        return (
                          <option value={therapy._id}>
                            {therapy.therapyName}{" "}
                            <span style={{ fontSize: "12px" }}>
                              ({data.category})
                            </span>
                          </option>
                        );
                      })}
                  </select>
                </div>
              </Col>
              <Col>
                <div class="form-group">
                  <label for="">Lawyer</label>
                  <select
                    name=""
                    id=""
                    class="form-select"
                    value={selectedTheraphist}
                    onChange={(e) => {
                      setSelectedTheraphist(e.target.value);
                    }}
                    required
                  >
                    <option value="0" selected disabled>
                      Select Lawyer
                    </option>
                    {Array.isArray(therapists) &&
                      therapists.map((theraphist) => {
                        return (
                          <option value={theraphist._id}>
                            {theraphist.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div class="form-group">
                  <label for="">Date</label>
                  <Space
                    direction="vertical"
                    style={{ width: "100%", borderColor: "transparent" }}
                  >
                    <DatePicker
                      style={{
                        width: "100%",
                        borderColor: "transparent",
                        outline: "none",
                        boxShadow: "0 0 0 transparent",
                      }}
                      onChange={(date, dateString) => {
                        setDateValue(dateString);
                      }}
                      format={"DD-MM-YYYY"}
                      className="form-control"
                    />
                  </Space>
                </div>
              </Col>
              <Col>
                <div class="form-group">
                  <label for="">Price</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    class="form-control price"
                    placeholder="Session Fee or Price"
                    onChange={(e) => {
                      return setPrice(e.target.value);
                    }}
                    value={price}
                    required
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div class="form-group">
                  <label for="Duration">Start Time</label>
                  <input
                    type="time"
                    name=""
                    id=""
                    class="form-control"
                    onChange={(e) => {
                      setStartTime(e.target.value);
                    }}
                    required
                  />
                </div>
              </Col>
              <Col>
                <div class="form-group end">
                  <label for="Duration">End Time</label>
                  <input
                    type="time"
                    name=""
                    id=""
                    class="form-control"
                    onChange={(e) => {
                      setEndTime(e.target.value);
                    }}
                    required
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-group">
                  <label for="Description">Description</label>
                  <textarea className="form-control descri"></textarea>
                </div>
              </Col>
            </Row>

            <button type="submit" className="scn-btn left">
              Add
            </button>
            {/* <Col>
                <button type="button"
                 className="scn-btn ryt"
                >
                  +
                </button>
                </Col> */}
          </form>
        </div>
      </div>
    </>
  );
}

export default ScheduleModal;

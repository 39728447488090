import React from "react";
import Header from "../../Components/Header/Header";
import "./Settings.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import placeholder from "../../assets/placeholder.webp";
import { Row, Container } from "react-bootstrap";
import * as Ai from "react-icons/ai";
function Settings() {
  return (
    <>
      <header>
        <Header />
      </header>
      <Container>
        <main>
          {/* <section className="main">
        <h1 className="admin_pro1">Settings</h1>
        <Tabs tabPosition="right" vertical>
          <TabList>
            <Tab>
              <p>Profile</p>
            </Tab>
            <Tab>
              <p>Privacy</p>
            </Tab>
          </TabList>
          <TabPanel>
            <div className="panel-content">
              <h2 className="admin_pro">Admin Profile</h2>

              <div className="container">
                <div className="row">
                  <div className="col-md-7">
                    <div className="card py-4">
                      <div className="text-center">
                        <img
                          src={placeholder}
                          width="100"
                          className="rounded-circle"
                        />
                      </div>

                      <div className="text-center mt-3">
                        <span className="bg-secondary p-1 px-4 rounded text-white">
                          Pro
                        </span>
                        <h5 className="mt-2 mb-0">Alexender Schidmt</h5>
                        <span>Admin</span>

                        <div className="px-4 mt-1">
                          <p className="fonts">
                            Consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna exercitation
                            ullamco laboris nisi ut aliquip ex ea commodo
                            consequat.{" "}
                          </p>
                        </div>
                          <button className="btn btn-success me-2">OK</button>
                          <button className="btn btn-danger"><Ai.AiOutlineClose/></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="panel">
              <h2 className="admin_pro">Privacy & Policy</h2>
            </div>
          </TabPanel>
        </Tabs>
      </section> */}
          <section class="main">
            <h1>Admin Settings</h1>
            <h3>Privacy & Policy</h3>
            <div class="container">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Aspernatur consequatur dolores nam accusantium distinctio. Beatae
              doloribus aspernatur dicta, quidem, incidunt quos facere, laborum
              saepe voluptate consequatur autem. Eveniet, nam voluptate! Lorem
              ipsum dolor sit amet consectetur adipisicing elit. Labore nam
              iusto, sequi nemo ducimus ipsam laboriosam, quod ad officia iste
              ab accusamus voluptate asperiores eaque enim neque fuga impedit
              corrupti! Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Nostrum id amet, eum quasi omnis cumque culpa eaque placeat sint
              aspernatur officia eos suscipit aut iste eveniet sunt delectus
              debitis animi!
            </div>
          </section>
        </main>
      </Container>
    </>
  );
}

export default Settings;

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ProtectAuthRoute, ProtectRoute } from "./routes/Protector";
import { Circles } from "react-loader-spinner";
import AppRoutes from "./routes/Routes";
import useCookies from "./hook/useCookies";

import "./App.css";
import Login from "./Pages/Login/Login";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const { getValueFromCookies } = useCookies({ key: "UserPersistent" });
  const cookiesValue = getValueFromCookies();

  const role = cookiesValue?.role;

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <Circles
            height="150"
            width="150"
            color="#047484"
            ariaLabel="circles-loading"
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <Router>
          <Routes>
            {AppRoutes.map((routes, key) => {
              return !routes.isProtected && !routes.isProtectedAuth ? (
                <Route
                  key={key}
                  path={routes.path}
                  element={routes.element}
                  exact="true"
                />
              ) : !routes.isProtected && routes.isProtectedAuth ? (
                <Route
                  key={key}
                  path={routes.path}
                  element={
                    <ProtectAuthRoute>{routes.element}</ProtectAuthRoute>
                  }
                  exact="true"
                ></Route>
              ) : (
                <Route
                  key={key}
                  path={routes.path}
                  element={<ProtectRoute>{routes.element}</ProtectRoute>}
                  exact="true"
                ></Route>
              );
            })}
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;

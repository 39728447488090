import BaseClient from "../Helper/BaseClient";
import { useState } from "react";
import { APIEndpoints } from "../constants/APIEndpoints";

const useInstructor = () => {
  const [therapists, setTherapists] = useState([]);
  const [therapistDetailsLoading, setTherapistDetailsLoading] = useState(null);
  const [loggedInstructorDetails, setLoggedInstructorDetails] = useState(null);
  const [actionLoading, setActionLoading] = useState({
    action: "",
    loading: false,
  });

  /* Get Therapists */
  const getTherapist = async () => {
    try {
      setActionLoading({ action: "Get", loading: true });
      await BaseClient.get(
        APIEndpoints.getTherapist,
        {
          onSuccess: (res) => {
            if (!Array.isArray(res.Instructors)) return;
            setTherapists(res.Instructors);
          },
        },
        {
          onFailed: (err) => {
            return console.log(err);
          },
        }
      );
    } finally {
      setActionLoading({ action: "Get", loading: false });
    }
  };

  /* Add Therpist */
  const addTheraphist = async (data, { onSuccess, onFailed }) => {
    try {
      setActionLoading({ action: "Add", loading: true });
      await BaseClient.post(APIEndpoints.addTherapist, data, {
        headers: { "Content-Type": "multipart/form-data" },
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setActionLoading({ action: "Add", loading: false });
    }
  };

  /* Edit Therapists */
  const editTherapist = async (data, id, { onSuccess, onFailed }) => {
    try {
      const payload = data;
      setActionLoading({ action: "Edit", loading: true });

      await BaseClient.put(APIEndpoints.editTherapist + `/${id}`, payload, {
        headers: { "Content-Type": "multipart/form-data" },
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setActionLoading({ action: "", loading: false });
    }
  };

  /* Delete Therapists */
  const deleteTherapist = async (id, { onSuccess, onFailed }) => {
    try {
      setActionLoading({ action: "Delete", loading: true });
      await BaseClient.delete(APIEndpoints.deleteTherapist + `/${id}`, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setActionLoading({ action: "", loading: false });
    }
  };

  // Get Logged Therpist Details
  const getCurrentLoggedTherapistDetails = async () => {
    setTherapistDetailsLoading(true);
    try {
      await BaseClient.get(APIEndpoints.therapistDetails, {
        onSuccess: (res) => {
          if (res?.data != null && res?.data != undefined) {
            window.sessionStorage.setItem(
              "TherapistDetails",
              JSON.stringify(res.data)
            );
            setLoggedInstructorDetails(res.data);
            setTherapistDetailsLoading(false);
          }
        },
        onFailed: (error) => {
          console.log("error", error);
        },
      });
    } finally {
      setTherapistDetailsLoading(false);
    }
  };

  // Enable Therapist
  const enableTherapistSingle = async (id, { onSuccess, onFailed }) => {
    try {
      setActionLoading({ action: "enable", loading: true });
      const payload = {};
      await BaseClient.put(APIEndpoints.enableTherapist + `/${id}`, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setActionLoading({ action: "", loading: false });
    }
  };

  // Disable Therapist
  const disableTherapistSingle = async (id, { onSuccess, onFailed }) => {
    try {
      setActionLoading({ action: "disable", loading: true });
      const payload = {};
      await BaseClient.put(APIEndpoints.disableTherapist + `/${id}`, payload, {
        onSuccess: onSuccess,
        onFailed: onFailed,
      });
    } finally {
      setActionLoading({ action: "", loading: false });
    }
  };

  return {
    getTherapist,
    addTheraphist,
    deleteTherapist,
    editTherapist,
    enableTherapistSingle,
    disableTherapistSingle,
    getCurrentLoggedTherapistDetails,
    loggedInstructorDetails,
    actionLoading,
    therapists,
    therapistDetailsLoading,
  };
};

export default useInstructor;

import React from "react";
import { Navigate, Route, redirect } from "react-router-dom";
import useCookies from "../hook/useCookies";
import jwt_decode from "jwt-decode";

export const ProtectRoute = ({ children }) => {
  const { getValueFromCookies } = useCookies({ key: "UserPersistent" });
  let cookiesValue = getValueFromCookies();

  const loggedUserRole = cookiesValue?.role;

  const { role } = children.props;

  if (cookiesValue == null) {
    return <Navigate to="/login" />;
  }

  if (!Array.isArray(role) || role == null || role.length == 0) {
    return children;
  }

  if (role.includes(loggedUserRole)) {
    return children;
  }

  return <Navigate to="/restricted-entry" />;
};

export const ProtectAuthRoute = ({ children }) => {
  const { checkValueIsExist } = useCookies({ key: "UserPersistent" });
  let isAuthenticated = checkValueIsExist();

  return !isAuthenticated ? children : <Navigate to="/" />;
};


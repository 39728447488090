import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Row, Col, Container } from "react-bootstrap";
import "./ScheduleManagement.css";
import { AppContext } from "../../context/AppContext";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import * as Fc from "react-icons/fc";
import * as Ai from "react-icons/ai";
import * as Bs from "react-icons/bs";
import * as Bi from "react-icons/bi";
import { toast, Toaster } from "react-hot-toast";
import Utils from "../../Utils/Utils";

function ScheduleManagementTherapist() {
  const navigate = useNavigate();
  const [therapistId, setTherapistId] = useState("");

  const { weeklyScheduleList, programs, dateScheduleList } =
    useContext(AppContext);
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    const therapistData = JSON.parse(
      sessionStorage.getItem("TherapistDetails")
    );

    if (therapistData) {
      setTherapistId(therapistData._id);
    }
  }, []);

  function findTherapyById(therapyId) {
    return programs.find((therapy) => therapy._id == therapyId);
  }

  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            width: "100%",
          },
        }}
      />
      <button
        className="add_btn"
        onClick={() => navigate(`/add-schedule-therapist/${therapistId}`)}
      >
        + Add
      </button>
      <h1>Schedule Management</h1>
      <section class="main_sec">
        <Tabs
          defaultActiveKey="All"
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          <Tab eventKey="All" title="Weekly Schedule">
            {weekDays.map((week) => {
              if (!weeklyScheduleList[week]) {
                return null;
              }
              return (
                <Col
                  className="card schedule_card"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <Row>
                    <Col>
                      <div className="session_control">
                        <h5 className="m-3" style={{ fontWeight: "300" }}>
                          Weekly Sessions -{" "}
                          <span style={{ fontWeight: "500" }}> {week}</span>
                        </h5>
                      </div>
                    </Col>
                  </Row>
                  <div class="d-flex">
                    {weeklyScheduleList[week].length !== 0 &&
                      weeklyScheduleList[week].map((schedule) => {
                        const theraphyDetails = programs.find((therapy) => {
                          return therapy._id == schedule.therapyId;
                        });
                        return (
                          <div className="card mapped_data" id="week_card">
                            <Container>
                              <Row>
                                <Col xl={7}>
                                  <p>
                                    <b>Service :</b>{" "}
                                    <span>
                                      {theraphyDetails?.therapyName ?? "N/A"}
                                    </span>
                                  </p>
                                  {/* <p>
                                    <b>Therapist :</b>{" "}
                                    <span>
                                      {weeklyScheduleList?.therapistDetails
                                        ?.name ?? "N/A"}
                                    </span>
                                  </p> */}
                                  {schedule.price && (
                                    <p>
                                      <b>Price :</b>{" "}
                                      <span>
                                        {`£ ${schedule?.price ?? "N/A"}`}
                                      </span>
                                    </p>
                                  )}
                                  {/* <p>
                                    <b>Description :</b>
                                    <span>
                                      {schedule?.description ?? "N/A"}
                                    </span>
                                  </p> */}
                                  <p className="text-wrap">
                                    <Ai.AiOutlineFieldTime />{" "}
                                    {schedule?.startTime ?? "N/A"} -{" "}
                                    {schedule?.endTime ?? "N/A"}
                                  </p>
                                  <p>
                                    Status :{" "}
                                    <span>
                                      {schedule.approved ? (
                                        <i
                                          className="approve_icon mx-auto"
                                          title="Approved"
                                        >
                                          <Fc.FcApproval />
                                        </i>
                                      ) : (
                                        <i
                                          className="approve_icon red mx-auto"
                                          title="Pending"
                                        >
                                          <Bs.BsFillPatchQuestionFill />
                                        </i>
                                      )}
                                    </span>
                                  </p>
                                </Col>
                                <Col xl={5} className="mx-auto text-center">
                                  {/* <button
                                    className="sched_btn mt-1 mb-3 p-1"
                                    onClick={() => {
                                      toast.success(
                                        "This feature is under development!😊👍 "
                                      );
                                    }}
                                  >
                                    RESCHEDULE
                                  </button> */}
                                  <button className="re-btn">
                                    <i>
                                      <Bi.BiReset />
                                    </i>
                                  </button>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        );
                      })}
                  </div>
                </Col>
              );
            })}
          </Tab>
          <Tab eventKey="daily" title="Date-Wise Schedule">
            <div class="container">
              <div className="card tab_card p-3">
                <div class="row">
                  {dateScheduleList != null &&
                    dateScheduleList.length != 0 &&
                    dateScheduleList.map((dateSchedule, key) => {
                      return dateSchedule.schedules.map((schedule, index) => {
                        return (
                          <div class="col-lg-4 col-md-6 col-sm-6" key={index}>
                            <div
                              class="card schedule_card p-0 date-card"
                              key={key}
                            >
                              <div class="date-sec">
                                <h3>
                                  <i>
                                    <Ai.AiTwotoneCalendar />
                                  </i>{" "}
                                  {dateSchedule.date != null
                                    ? Utils.formatDate(dateSchedule.date)
                                    : "N/A"}
                                </h3>
                              </div>
                              <div class="therapy_cont_ p-3">
                                <p>
                                  <b>Service:</b>{" "}
                                  <span>
                                    {schedule.therapyId != null
                                      ? findTherapyById(schedule.therapyId)
                                          .therapyName
                                      : "N/A"}
                                  </span>
                                </p>
                                {schedule.price && (
                                  <p>
                                    <b>Price:</b> <span>{schedule?.price}</span>
                                  </p>
                                )}
                                {/* <p>
                                  <b>Description:</b>
                                  <span className="description">
                                    {schedule?.description}
                                  </span>
                                </p> */}
                                <p>
                                  <Ai.AiOutlineFieldTime /> 10:00-12:00
                                </p>
                                <p>
                                  <b>Status:</b>{" "}
                                  <span>
                                    {schedule.approved ? (
                                      <i
                                        className="approve_icon mx-auto"
                                        title="Approved"
                                      >
                                        <Fc.FcApproval />
                                      </i>
                                    ) : (
                                      <i
                                        className="approve_icon red mx-auto"
                                        title="Pending"
                                      >
                                        <Bs.BsFillPatchQuestionFill />
                                      </i>
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      });
                    })}
                  {dateScheduleList.length == 0 && (
                    <h4 className="text-center" style={{ color: "#bbb3b3" }}>
                      no sessions are available
                    </h4>
                  )}
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </section>
    </>
  );
}

export default ScheduleManagementTherapist;

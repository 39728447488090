import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import Utils from "../../Utils/Utils";

function AllStreams({ onStreamStart }) {
  const { allStreams, programs, registerdUsers } = useContext(AppContext);

  useEffect(() => {
    console.log("registerdUsers", registerdUsers);
  }, [registerdUsers]);

  return (
    <>
      <Container className="py-4">
        {allStreams.schedules &&
          Array.isArray(allStreams.schedules) &&
          allStreams.schedules.map((stream) => {
            console.log("stream", stream);
            const therapist = allStreams.therapistDetails;
            const sessiondate = allStreams.bookings;
            const schedule = stream.scheduleDetails;
            const userId = stream.userIds;
            const therapy = programs.find((x) => x._id == schedule.therapyId);

            const findUser = registerdUsers.filter((y) =>
              userId.includes(y._id)
            );

            return (
              <div class="card schedule_card schedule_details_card mt-3">
                <Row>
                  <Col xl={3}>
                    <p style={{ fontWeight: "500" }}>
                      Lawyer Name : <span>{therapist?.name ?? "N/A"}</span>
                    </p>
                    <p style={{ fontWeight: "500" }}>
                      Schedule Type:{" "}
                      {
                        !stream.dateSchedule ? <span>Weekly {`(${stream?.week ?? "N/A"})`}</span>
                      : <span>Date-wise ({Utils.formatDate(stream.date)})</span>}
                    </p>
                    <div className="det">
                      <div className="icon_div">
                        <p style={{ fontWeight: "500" }}>
                          Price : <span>{schedule?.price ?? "N/A"}</span>
                        </p>
                      </div>
                      <div className="icon_div">
                        <p style={{ fontWeight: "500" }}>
                          Time :{" "}
                          <span>
                            {schedule.startTime} to {schedule.endTime}
                          </span>
                        </p>
                      </div>
                      {/* <div className="icon_div">
                        <p style={{ fontWeight: "500" }}>
                          Info :{" "}
                          <span style={{ fontWeight: "400" }}>
                            Here shows the instructions
                          </span>
                        </p>
                      </div> */}
                    </div>
                  </Col>
                  <Col xl={6}>
                    <p style={{ fontWeight: "500" }}>
                      Client Name :{" "}
                      <span>{findUser.map((name) => name.name).join(", ")}</span>
                    </p>
                    <p style={{ fontWeight: "500" }}>
                      Service Name :{" "}
                      <span>{therapy?.therapyName ?? "N/A"}</span>{" "}
                    </p>

                    <p style={{ fontWeight: "500" }}>
                      Date :{" "}
                      <span>{Utils.formatDate(stream?.date ?? "N/A")}</span>{" "}
                    </p>
                    <p className="">{therapy.therapyDescription}</p>
                    <span></span>
                  </Col>
                  <Col xl={3}>
                    <div className="row mt-3">
                      <button
                        class="start_stream_btn mx-auto"
                        onClick={() => onStreamStart({ ...stream, therapist })}
                        // disabled={!selectedTherpy}
                      >
                        {stream.streamStatus == "STOPPED" ? "Restart" : "Start"}
                      </button>
                    </div>
                    <div class="live_indicator"></div>
                    {/* <div className="row mt-3 mx-auto approval">
                      <h6 className="text-center">
                        Status :{" "}
                        <span>
                          {" "}
                          <i className="approve_icon mx-auto">
                            <Fc.FcApproval />
                          </i>
                          <i className="approve_icon mx-auto"><Ai.AiFillCloseSquare /></i>
                        </span>
                      </h6>
                    </div> */}
                  </Col>
                </Row>
              </div>
            );
          })}
      </Container>
    </>
  );
}

export default AllStreams;
